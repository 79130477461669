import React from 'react';
import './App.css';
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Paper,
    TextField
} from "@mui/material";
import {Image} from "mui-image";
import Typography from "@mui/material/Typography";

export default function App() {
    const [email, setEmail] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [openResponse, setOpenResponse] = React.useState(false);
    const [responseMessage, setResponseMessage] = React.useState("");

    const handleSubmit = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL+"/mktplregproc", {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body: JSON.stringify({email: email, firstName: firstName, lastName: lastName, company: company, location: window.location.search})
        });

        response.json().then((data) => {
            setResponseMessage(data.message);
            setOpenResponse(true);
        })
    };

    const handleCancelResponseMessage = () => {
        setOpenResponse(false);
        setResponseMessage("");
        window.location.href = "https://docs.lacework.com"
    }


    return (
        <Container maxWidth={"sm"} style={{padding: 20}}>
            <Image src="/images/Fortinet-logo-rgb-black-red.png" width="200px" style={{padding: 10}}/>
            <Grid container>
            <Image src="/images/FortiCNAPP-200.png" width="50px" style={{padding: 10}}/>
            <Typography variant="h4" color="inherit" component="div" sx={{flexGrow: 1}} style={{padding: 15}}>
                Lacework FortiCNAPP
            </Typography>
            </Grid>
            <Typography variant="h6" color="inherit" component="div" sx={{flexGrow: 1}} style={{paddingBottom: 15, paddingLeft: 15}}>
                Finish setting up your Lacework account
            </Typography>
            <Paper sx={{width: '100%', mb: 2, borderRadius: "25px"}} style={{padding: 50, backgroundColor: '#2CCCD3'}}
                   elevation={24}>
                <div>Please complete this form. You will receive an email with instructions on how to access your
                    Lacework environment.
                </div>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    type="email"
                    fullWidth
                    error={email.length < 4}
                    helperText={email.length < 4 ? 'Min length > 3' : ''}
                    onChange={(event) => setEmail(event.target.value)}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    label="First Name"
                    type="text"
                    fullWidth
                    error={firstName.length < 3}
                    helperText={firstName.length < 3 ? 'Min length > 2' : ''}
                    onChange={(event) => setFirstName(event.target.value)}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="lastName"
                    label="Last Name"
                    type="text"
                    fullWidth
                    error={lastName.length < 3}
                    helperText={lastName.length < 3 ? 'Min length > 2' : ''}
                    onChange={(event) => setLastName(event.target.value)}
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="company"
                    label="Company"
                    type="text"
                    fullWidth
                    error={company.length < 3}
                    helperText={company.length < 3 ? 'Min length > 2' : ''}
                    onChange={(event) => setCompany(event.target.value)}
                    variant="standard"
                />
                <Dialog open={openResponse}>
                    <DialogContent>
                        <DialogContentText>
                            {responseMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCancelResponseMessage}>Ok</Button>
                    </DialogActions>
                </Dialog>
                <Button size="small" variant="contained" onClick={handleSubmit}>Submit</Button>
            </Paper>
        </Container>

    );
}
